export enum HaulRoadLayerTypeResponse {
  CenterLine = 'center_line',
  Edges = 'edges',
  GradientAnalysis = 'gradient_analysis',
  WidthAnalysis = 'width_analysis',
}

export enum HaulRoadLayerType {
  CenterLine = 'centerLine',
  Edges = 'edges',
  GradientAnalysis = 'gradientAnalysis',
  WidthAnalysis = 'widthAnalysis',
}
