import { IconIdentifier } from '@aus-platform/design-system';
import { FeatureFlag, UserType } from '../../shared/enums';
import { NavItemGroup } from '../components/accordion-sidenav/types';
import { RoutesEnum } from 'shared/routes';

export const navItems: NavItemGroup[] = [
  {
    itemType: 'Dashboard',
    items: [
      {
        itemName: 'Dashboard',
        itemKeys: ['/dashboard'],
        route: RoutesEnum.Dashboard,
        iconIdentifier: IconIdentifier.Dashboard,
      },
      {
        itemName: 'Reports',
        itemKeys: ['/reports'],
        route: RoutesEnum.Reports,
        iconIdentifier: IconIdentifier.FileCheck,
        enableForFeature: FeatureFlag.PowerBIReport,
      },
    ],
  },
  {
    itemType: 'Maps',
    items: [
      {
        itemName: 'Projects',
        itemKeys: ['/projects'],
        route: RoutesEnum.Projects,
        iconIdentifier: IconIdentifier.Site,
      },
      {
        itemName: '3D Map',
        itemKeys: ['/3d-map'],
        route: RoutesEnum.Map3D,
        iconIdentifier: IconIdentifier.Map3D,
      },
      {
        itemName: '2D Swipe Map',
        itemKeys: ['/swipe-map'],
        route: RoutesEnum.SwipeMap,
        iconIdentifier: IconIdentifier.SwipeMap,
      },
      {
        itemName: '3D Swipe Map',
        itemKeys: ['/3d-swipe-map'],
        route: RoutesEnum.SwipeMap3D,
        iconIdentifier: IconIdentifier.SwipeMap,
        enableForFeature: FeatureFlag.SwipeMap3d,
      },
    ],
  },
  {
    itemType: 'Management',
    items: [
      {
        itemName: 'Access Tags',
        itemKeys: ['/access-tags'],
        route: RoutesEnum.AccessTags,
        iconIdentifier: IconIdentifier.Tag,
      },
      {
        itemName: 'Users',
        itemKeys: ['/users'],
        route: RoutesEnum.Users,
        iconIdentifier: IconIdentifier.People,
      },
      {
        itemName: 'User Groups',
        itemKeys: ['/user-groups'],
        route: RoutesEnum.UserGroups,
        iconIdentifier: IconIdentifier.UserGroup,
      },
    ],
  },
  {
    itemType: 'Processing',
    items: [
      {
        itemName: 'Processing',
        itemKeys: ['/processing'],
        //TODO: use Enum.
        route: '/processing',
        iconIdentifier: IconIdentifier.Gears,
        enableForFeature: FeatureFlag.Processing,
      },
      {
        itemName: 'Org Settings',
        itemKeys: ['/org-settings'],
        route: null,
        iconIdentifier: IconIdentifier.GearFill,
        enableForFeature: FeatureFlag.Processing,
        includeUserRole: [UserType.OrgAdmin],
      },
    ],
  },
];
