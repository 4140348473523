import {
  Button,
  ButtonVariant,
  CheckBox,
  CheckboxAlignment,
  Divider,
  FormMessage,
  FormMessageVariant,
  IconIdentifier,
  Input,
  InputGroup,
  SelectOption,
  toast,
  ToggleSwitch,
} from '@aus-platform/design-system';
import { filter, isEmpty, isNil, without, xor } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MapTool } from '../../../map-3d-container/map-3d-tools';
import { WorkspaceLayer, WorkspaceLayerListObj } from '../../shared';
import { setVectorLayerVisibility } from '../map-3d-workspace';
import {
  FormErrorMessages,
  SmartDetectMarkArea,
  SmartDetectOutput,
  SmartDetectOutputMapping,
} from './enums';
import { SmartDetectFormValues } from './types';
import { useAppSelector } from 'app/hooks';
import { IPolygonTool, PolygonTool } from 'map-3d/shared';
import {
  selectActiveWorkspaceLayersByFeatureType,
  selectMap3dDataset,
  selectMap3DState,
  selectMap3dWorkspace,
  setCurrentActiveMapTool,
} from 'map-3d/shared/map-3d-slices';
import {
  FeatureGeometryType,
  FeatureResponseData,
  FeatureType,
  handleResponseErrorMessage,
  handleResponseMessage,
  LayerType,
  SmartDetectCreatePayload,
  useFeatureList,
} from 'shared/api';
import { useAddSmartDetectRequest } from 'shared/api/workspace/smart-detect-api';
import { useInputFields } from 'shared/hooks';

const smartDetectOutputs: SmartDetectOutput[] = [
  SmartDetectOutput.BenchToeCrest,
  SmartDetectOutput.BuildingFootprintsOrRoofTops,
  SmartDetectOutput.DrainageAnalysis,
  SmartDetectOutput.HeapDetection,
  SmartDetectOutput.PondsAndLakes,
  SmartDetectOutput.RiversAndStreams,
  SmartDetectOutput.TreeCanopies,
  SmartDetectOutput.MetalledRoads,
  SmartDetectOutput.UnMetalledRoads,
];

const initialSmartDetectFormValues: SmartDetectFormValues = {
  activeOrtho: null,
  selectedOutputs: [],
  smartAreaWkt: undefined,
  workspaceLayer: null,
  smartDetectOutputNames: Object.fromEntries(
    smartDetectOutputs.map((output) => [output, '']),
  ) as Record<SmartDetectOutput, string>,
};

export const GenerateSmartDetectForm: React.FC<{
  setShowSmartDetectForm: (boolean) => void;
}> = ({ setShowSmartDetectForm }) => {
  // States.
  const [currentMarkedArea, setCurrentMarkedArea] =
    useState<SmartDetectMarkArea>(SmartDetectMarkArea.Draw);
  const [isDrawPolygonToolEnabled, setIsDrawPolygonToolEnabled] =
    useState(false);

  // Selectors.
  const dispatch = useDispatch();
  const polygonTool = useRef<IPolygonTool>();
  const { cesiumProxy } = useAppSelector(selectMap3DState);
  const { workspaceLayers } = useAppSelector(selectMap3dWorkspace);
  const { selectedTerrainIteration } = useAppSelector(selectMap3dDataset);

  // Hooks.
  const { values, errors, onBlur, onFocus, setValues, setErrors } =
    useInputFields(initialSmartDetectFormValues);

  // APIs.
  const {
    data: featuresResponse,
    isFetching: isLoadingFeaturesResponse,
    isSuccess: isSuccessFeaturesResponse,
    isError: isErrorFeaturesResponse,
    error: featuresResponseError,
    refetch: refetchFeatures,
  } = useFeatureList(
    values.workspaceLayer?.value.id ?? null,
    FeatureType.Polygon,
    false,
  );

  const {
    mutate: sendSmartDetectRequest,
    isPending: isSmartDetectRequestLoading,
    data: smartDetectResponse,
    isSuccess: isSmartDetectRequestSuccess,
    isError: isSmartDetectRequestError,
    error: smartDetectError,
  } = useAddSmartDetectRequest();

  const activeOrthoOptions = useMemo(() => {
    const orthoLayers = filter(workspaceLayers, {
      type: LayerType.Orthomosaic,
    })
      .sort((layer1, layer2) => (layer2.zIndex || 0) - (layer1.zIndex || 0))
      .map((layer) => ({ label: layer.name, value: layer.id }));

    setValues({ ...values, activeOrtho: orthoLayers[0] });
    return orthoLayers;
  }, [workspaceLayers]);

  const activeLayers = useAppSelector((state) =>
    selectActiveWorkspaceLayersByFeatureType(
      state,
      FeatureGeometryType.Polygon,
    ),
  );

  const polygonLayersSelectOption: SelectOption<WorkspaceLayer>[] =
    activeLayers.map((activeLayer) => ({
      label: activeLayer.name,
      value: activeLayer,
    }));

  const isParentCheckboxChecked = useMemo(
    () => values.selectedOutputs.length === smartDetectOutputs.length,
    [values.selectedOutputs],
  );

  const isParentCheckboxIndeterminate = useMemo(
    () =>
      values.selectedOutputs.length > 0 &&
      values.selectedOutputs.length < smartDetectOutputs.length,
    [values.selectedOutputs],
  );

  // useEffects.
  useEffect(() => {
    if (cesiumProxy) {
      polygonTool.current = new PolygonTool(cesiumProxy.cesiumViewer);
    }
    return () => {
      setVectorLayerVisibility(visibleWorkspaceLayers, true);
      polygonTool.current?.deleteAllPolygons();
      polygonTool.current?.deactivate();
    };
  }, []);

  useEffect(() => {
    handleResponseErrorMessage(isErrorFeaturesResponse, featuresResponseError);
  }, [isErrorFeaturesResponse, featuresResponseError]);

  useEffect(() => {
    handleResponseMessage(
      isSmartDetectRequestSuccess,
      isSmartDetectRequestError,
      smartDetectResponse,
      smartDetectError,
    );
    if (isSmartDetectRequestSuccess) {
      setVectorLayerVisibility(visibleWorkspaceLayers, true);
      setShowSmartDetectForm(false);
    }
  }, [
    smartDetectResponse,
    isSmartDetectRequestSuccess,
    isSmartDetectRequestError,
  ]);

  useEffect(() => {
    if (isSuccessFeaturesResponse && !isNil(featuresResponse)) {
      loadPolygonsFromWorkspace(featuresResponse.data.features);
    }
  }, [isSuccessFeaturesResponse, featuresResponse]);

  // useEffect - Handle addition & deletion of polygons when selected layer is changed.
  useEffect(() => {
    if (isNil(values.workspaceLayer)) {
      return;
    }

    refetchFeatures();

    // Clear all drawn smart detect polygons.
    deleteSmartDetectPolygonsByMarkedArea(SmartDetectMarkArea.Draw);

    /**
     * Clear all polygons from previously selected layers
     * (excluding the current selected layers polygons)
     **/
    const features = values.workspaceLayer?.value.features;
    const selectedSmartDetectPolygonIds: Array<string> | undefined =
      features?.map((feature) => {
        return feature.id + 'smartDetect';
      });
    deleteSmartDetectPolygonsByMarkedArea(
      SmartDetectMarkArea.LayerFromWorkspace,
      selectedSmartDetectPolygonIds,
    );
  }, [values.workspaceLayer]);

  // useMemo.
  const visibleWorkspaceLayers: WorkspaceLayerListObj = useMemo(
    () =>
      Object.keys(workspaceLayers).reduce(
        (toggledOnLayersListObject, layerId) => {
          if (workspaceLayers[layerId].show) {
            toggledOnLayersListObject[layerId] = workspaceLayers[layerId];
            return toggledOnLayersListObject;
          }
          return toggledOnLayersListObject;
        },
        {},
      ),
    [workspaceLayers],
  );

  // Handlers.
  // TODO: Create 2 separate functions for handling exit drawing mode and enter drawing mode.
  const startDrawingHandler = () => {
    // Drawing Mode.
    polygonTool.current?.activate({
      smartDetectPolygons: SmartDetectMarkArea.Draw,
    });
    dispatch(setCurrentActiveMapTool(MapTool.None));
    setIsDrawPolygonToolEnabled(true);
  };

  const endDrawingHandler = () => {
    if (polygonTool.current) {
      polygonTool.current.deactivate();
    }
    setIsDrawPolygonToolEnabled(false);
  };

  const handleResetDrawing = () => {
    if (polygonTool.current?.isAnyPolygonDrawn) {
      polygonTool.current.deleteAllCurrentPolygons();
    }
  };

  const handleParentCheckboxClick = () => {
    const newSelectedOutputs = !isParentCheckboxChecked
      ? [...smartDetectOutputs]
      : [];

    setValues({
      ...values,
      selectedOutputs: newSelectedOutputs,
      smartDetectOutputNames: Object.fromEntries(
        smartDetectOutputs.map((output) => [
          output,
          newSelectedOutputs.includes(output)
            ? `${output}_${values.activeOrtho?.label || ''}`
            : '',
        ]),
      ) as Record<SmartDetectOutput, string>,
    });
  };

  const handleOutputSelect = (output: string) => {
    const newSelectedOutputs = values.selectedOutputs.includes(output)
      ? without(values.selectedOutputs, output)
      : [...values.selectedOutputs, output];

    setValues((prev) => ({
      ...prev,
      selectedOutputs: newSelectedOutputs,
      smartDetectOutputNames: {
        ...prev.smartDetectOutputNames,
        [output]: `${output}_${values.activeOrtho?.label || ''}`,
      },
    }));
  };

  const handleOrthoSelect = (option: SelectOption | null) => {
    setValues((prev) => ({
      ...prev,
      activeOrtho: option,
      //Below, changing the default output names based on active ortho.
      smartDetectOutputNames: Object.fromEntries(
        smartDetectOutputs.map((output) => [
          output,
          prev.selectedOutputs.includes(output)
            ? `${output}_${option?.label || ''}`
            : '',
        ]),
      ) as Record<SmartDetectOutput, string>,
    }));
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const output = name.split('.')[1] as SmartDetectOutput;

    setValues({
      ...values,
      smartDetectOutputNames: {
        ...values.smartDetectOutputNames,
        [output]: value,
      },
    });

    if (isEmpty(value)) {
      setErrors({
        ...errors,
        smartDetectOutputNames: FormErrorMessages.LayerNameNotProvided,
      });
    } else {
      setErrors({
        ...errors,
        smartDetectOutputNames: '',
      });
    }
  };

  const deleteSmartDetectPolygonsByMarkedArea = (
    markArea: SmartDetectMarkArea,
    excludePolygons?: string[],
  ) => {
    const smartDetectPolygonsIds = polygonTool.current
      ?.getPolygonByProperty('smartDetectPolygons', markArea)
      .map((smartDetectPolygon) => {
        return smartDetectPolygon.id;
      });

    /**
     * XOR finds the symmetric difference.
     * Ref - https://en.wikipedia.org/wiki/Symmetric_difference
     * In this case, removes the excludePolygons from the heapPolygons.
     */
    const polygonsToDelete = xor(smartDetectPolygonsIds, excludePolygons);

    for (const polygon of polygonsToDelete) {
      polygonTool.current?.deleteById(polygon);
    }
  };

  const generateSmartDetect = () => {
    const payload: SmartDetectCreatePayload = {
      iteration: selectedTerrainIteration?.id || '',
      inputOrthoLayer: values.activeOrtho?.value || '',
      smartDetectOutputs: values.selectedOutputs.map((output) => ({
        outputType: SmartDetectOutputMapping[output],
        name: values.smartDetectOutputNames[output] as string,
      })),
    };

    if (polygonTool.current?.isAnyPolygonDrawn) {
      let wktString: string | null = null;
      const polygonIds = polygonTool.current?.currentDrawnPolygonIds;
      if (polygonIds.length > 1) {
        toast.error('Please draw only one polygon.');
        return;
      }
      const polygon = polygonTool.current?.currentDrawnPolygons[polygonIds[0]];
      if (isEmpty(polygonIds)) {
        toast.error('Please draw a polygon first.');
        return;
      }
      wktString = polygon.exportWKT().wkt;
      payload.smartAreaWkt = wktString;
    } else {
      setIsDrawPolygonToolEnabled(false);
    }

    if (values.workspaceLayer) {
      payload.inputAOILayer = values.workspaceLayer.value.id;
    }
    sendSmartDetectRequest(payload);
  };

  const isSubmitDisabled =
    !values.activeOrtho ||
    values.selectedOutputs.length === 0 ||
    errors.smartDetectOutputNames.length !== 0;

  const isOutputSelectDisabled = () => {
    if (currentMarkedArea === SmartDetectMarkArea.Draw) {
      return !polygonTool.current?.isAnyPolygonDrawn;
    } else {
      return !values.workspaceLayer;
    }
  };

  const onToggleChange = (toggledValue) => {
    if (toggledValue === SmartDetectMarkArea.Draw) {
      setVectorLayerVisibility(visibleWorkspaceLayers, true);
      deleteSmartDetectPolygonsByMarkedArea(
        SmartDetectMarkArea.LayerFromWorkspace,
      );
      setErrors({
        ...errors,
        workspaceLayer: '',
      });
      setValues({
        ...values,
        workspaceLayer: null,
      });
    } else {
      setIsDrawPolygonToolEnabled(false);
      setVectorLayerVisibility(visibleWorkspaceLayers, false);
      deleteSmartDetectPolygonsByMarkedArea(SmartDetectMarkArea.Draw);

      if (polygonTool.current) {
        polygonTool.current.deactivate();
      }

      if (
        isNil(polygonLayersSelectOption) ||
        polygonLayersSelectOption.length === 0
      ) {
        setErrors({
          ...errors,
          workspaceLayer: FormErrorMessages.NoActiveWorkspaceLayer,
        });
      }
      setValues({ ...values });
    }

    setCurrentMarkedArea(toggledValue);
  };

  // Helpers.
  // TODO: refactor this to be reusable in every select from layers modules.
  const loadPolygonsFromWorkspace = (
    featureResponse: FeatureResponseData[],
  ) => {
    for (const feature of featureResponse) {
      if (feature.type === FeatureType.Polygon) {
        polygonTool.current?.importWKT(
          feature.geometry,
          feature.id,
          {
            smartDetectPolygons: SmartDetectMarkArea.LayerFromWorkspace,
          },
          feature.name,
        );
      }
    }
  };

  return (
    <>
      <div className="map-3d-smart-detect">
        <form className="map-3d-smart-detect__form">
          <InputGroup>
            <Input.Label>Active Terrain</Input.Label>
            <Input.Text disabled value={selectedTerrainIteration?.name} />
          </InputGroup>
          <InputGroup>
            <Input.Label>Active Orthomosaic</Input.Label>
            <Input.Select
              options={activeOrthoOptions}
              value={values.activeOrtho}
              onChange={handleOrthoSelect}
              error={errors.activeOrtho}
              isClearable={false}
            />
          </InputGroup>

          <InputGroup className="map-3d-smart-detect__form__drawing">
            <Input.Label className="map-3d-smart-detect__form__drawing__heading">
              Mark Area
            </Input.Label>
            <ToggleSwitch
              leftTitle={SmartDetectMarkArea.Draw}
              rightTitle={SmartDetectMarkArea.LayerFromWorkspace}
              leftSelected={currentMarkedArea === SmartDetectMarkArea.Draw}
              onChange={onToggleChange}
            />
            {currentMarkedArea === SmartDetectMarkArea.Draw ? (
              <>
                {isDrawPolygonToolEnabled ? (
                  <div className="map-3d-smart-detect__form__drawing__container">
                    <Button
                      onClick={endDrawingHandler}
                      leftIconIdentifier={IconIdentifier.Polygon}
                      variant={ButtonVariant.Primary}
                      disabled={!values.activeOrtho}
                    >
                      Exit Drawing Mode
                    </Button>
                    <Button
                      onClick={handleResetDrawing}
                      leftIconIdentifier={IconIdentifier.Reset}
                      variant={ButtonVariant.Outline}
                      disabled={!values.activeOrtho}
                      className="map-3d-smart-detect__form__drawing__container__btn--redo"
                    >
                      Reset Drawing
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={startDrawingHandler}
                    leftIconIdentifier={IconIdentifier.Polygon}
                    variant={ButtonVariant.Outline}
                    disabled={!values.activeOrtho}
                  >
                    Enter Drawing Mode
                  </Button>
                )}
              </>
            ) : (
              <InputGroup className="volume-calculation__select-layer">
                <Input.Label>Select Layer</Input.Label>
                <Input.Select
                  placeholder="Select Layer from Workspace"
                  options={polygonLayersSelectOption}
                  value={values.smartAreaWkt}
                  isLoading={isLoadingFeaturesResponse}
                  onChange={(selectedLayer: SelectOption<WorkspaceLayer>) => {
                    setValues({
                      ...values,
                      workspaceLayer: selectedLayer,
                    });
                  }}
                  {...{ onBlur, onFocus }}
                />
              </InputGroup>
            )}
          </InputGroup>

          <InputGroup className="map-3d-smart-detect__form__select-output">
            <div className="map-3d-smart-detect__form__select-output__parent-checkbox">
              <Input.Label>Select Outputs</Input.Label>
              <Input.CheckBox
                checked={isParentCheckboxChecked}
                indeterminate={isParentCheckboxIndeterminate}
                onClick={handleParentCheckboxClick}
                disabled={isOutputSelectDisabled()}
              />
            </div>

            {smartDetectOutputs.map((output) => (
              <div key={output}>
                <CheckBox
                  title={output}
                  checked={values.selectedOutputs.includes(output)}
                  alignCheckbox={CheckboxAlignment.Right}
                  onClick={() => handleOutputSelect(output)}
                  disabled={isOutputSelectDisabled()}
                  showCard
                />
                {values.selectedOutputs.includes(output) && (
                  <>
                    <Input.Text
                      name={`smartDetectOutputNames.${output}`}
                      className="map-3d-smart-detect__form__select-output__text"
                      value={values.smartDetectOutputNames[output]}
                      onChange={handleTextChange}
                      {...{ onBlur, onFocus }}
                    />
                    {values.smartDetectOutputNames[output] === '' && (
                      <FormMessage
                        className="map-3d-smart-detect__form__select-output__text__form-message"
                        variant={FormMessageVariant.Error}
                        showIcon={true}
                        iconIdentifier={IconIdentifier.ExclamationCircle}
                        message={FormErrorMessages.LayerNameNotProvided}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </InputGroup>
        </form>
        <Divider text="" />
        <div className="map-3d-smart-detect__footer">
          <Button
            onClick={generateSmartDetect}
            disabled={isSubmitDisabled}
            isLoading={isSmartDetectRequestLoading}
          >
            Start Detection
          </Button>
        </div>
      </div>
    </>
  );
};
