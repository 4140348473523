export enum HRALineOptions {
  Draw = 'Draw',
  LayerFromWorkspace = 'Layer From Workspace',
}

export enum HaulRoadLayerType {
  CenterLineLayer = 'Haul Road Center Line Layer',
  EdgesLayer = 'Haul Road Edges Layer',
}

export enum FormErrorMessages {
  NoActiveCenterLineLayer = 'Please select exactly one line feature to proceed.',
  NoActiveEdgeLineLayer = 'Please select exactly two line features to continue.',
}
