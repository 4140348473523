export enum HeapMarkArea {
  Draw = 'draw',
  LayerFromWorkspace = 'layer_from_workspace',
}

export enum FormErrorMessages {
  NoDSMForSelectedIteration = 'DSM not uploaded for the selected iteration.',
  NoDSMForSelectedSite = 'DSM not uploaded for the selected site.',
  NoDSMForCurrentIteration = 'DSM not uploaded for the current iteration.',
  NoActiveWorkspaceLayer = 'No polygon layers in workspace. Add layers and try again.',
}
