export const enum WorkspaceTools {
  PolygonTool = 'polygonTool',
  LineTool = 'lineTool',
  PointTool = 'pointTool',
  TextboxTool = 'textboxTool',
  SelectFeatureTool = 'selectFeatureTool',
}

export const enum ToolEvent {
  Blur = 'blur',
  Focus = 'focus',
}
