export enum FeatureFlag {
  GenerateAnalytics = 'generate_analytics',
  HaulRoadAnalytics = 'haul_road_analytics',
  MBTiles = 'mbtiles',
  PowerBIReport = 'power_bi_report',
  Processing = 'processing',
  ProcessingWorkflow = 'processing_workflow',
  SlopeMap = 'slope_map',
  SmartDetectAnalytics = 'smart_detect_analytics',
  SwipeMap3d = 'swipe_map_3d',
}
