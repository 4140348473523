import { RoutesEnum } from '../shared/routes';

export const noHeaderPaths = [
  RoutesEnum.Map3DWorkspace.toString(),
  RoutesEnum.Map3D.toString(),
  RoutesEnum.SwipeMap.toString(),
  RoutesEnum.Dashboard.toString(),
  RoutesEnum.Processing.toString(),
  RoutesEnum.SwipeMap3D.toString(),
];
