import { HorizontalCRS, VerticalCRS } from '../enums';

export const CRSMapping = {
  'WGS_84+Ellipsoidal':
    'GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]]',
  'WGS_84+EGM96':
    'COMPOUNDCRS["WGS_84 + EGM96",GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'WGS_84+EGM2008':
    'COMPOUNDCRS["WGS_84 + EGM2008",GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'WGS_84+AHD71':
    'COMPOUNDCRS["WGS_84 + AHD71",GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'WGS_84+CGVD2013':
    'COMPOUNDCRS["WGS_84 + CGVD2013",GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'WGS_84/UTM ZONE 42+Ellipsoidal':
    'PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]]',
  'WGS_84/UTM ZONE 42+EGM96':
    'COMPOUNDCRS["WGS_84/UTM ZONE 42 + EGM96",PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'WGS_84/UTM ZONE 42+EGM2008':
    'COMPOUNDCRS["WGS_84/UTM ZONE 42 + EGM2008",PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'WGS_84/UTM ZONE 42+AHD71':
    'COMPOUNDCRS["WGS_84/UTM ZONE 42 + AHD71",PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'WGS_84/UTM ZONE 42+CGVD2013':
    'COMPOUNDCRS["WGS_84/UTM ZONE 42 + CGVD2013",PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'WGS_84/UTM ZONE 43+Ellipsoidal':
    'PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]]',
  'WGS_84/UTM ZONE 43+EGM96':
    'COMPOUNDCRS["WGS_84/UTM ZONE 43 + EGM96",PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'WGS_84/UTM ZONE 43+EGM2008':
    'COMPOUNDCRS["WGS_84/UTM ZONE 43 + EGM2008",PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'WGS_84/UTM ZONE 43+AHD71':
    'COMPOUNDCRS["WGS_84/UTM ZONE 43 + AHD71",PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'WGS_84/UTM ZONE 43+CGVD2013':
    'COMPOUNDCRS["WGS_84/UTM ZONE 43 + CGVD2013",PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'WGS_84/UTM ZONE 44+Ellipsoidal':
    'PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]]',
  'WGS_84/UTM ZONE 44+EGM96':
    'COMPOUNDCRS["WGS_84/UTM ZONE 44 + EGM96",PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'WGS_84/UTM ZONE 44+EGM2008':
    'COMPOUNDCRS["WGS_84/UTM ZONE 44 + EGM2008",PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'WGS_84/UTM ZONE 44+AHD71':
    'COMPOUNDCRS["WGS_84/UTM ZONE 44 + AHD71",PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'WGS_84/UTM ZONE 44+CGVD2013':
    'COMPOUNDCRS["WGS_84/UTM ZONE 44 + CGVD2013",PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'WGS_84/UTM ZONE 45+Ellipsoidal':
    'PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]]',
  'WGS_84/UTM ZONE 45+EGM96':
    'COMPOUNDCRS["WGS_84/UTM ZONE 45 + EGM96",PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'WGS_84/UTM ZONE 45+EGM2008':
    'COMPOUNDCRS["WGS_84/UTM ZONE 45 + EGM2008",PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'WGS_84/UTM ZONE 45+AHD71':
    'COMPOUNDCRS["WGS_84/UTM ZONE 45 + AHD71",PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'WGS_84/UTM ZONE 45+CGVD2013':
    'COMPOUNDCRS["WGS_84/UTM ZONE 45 + CGVD2013",PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'WGS_84/UTM ZONE 46+Ellipsoidal':
    'PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]]',
  'WGS_84/UTM ZONE 46+EGM96':
    'COMPOUNDCRS["WGS_84/UTM ZONE 46 + EGM96",PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'WGS_84/UTM ZONE 46+EGM2008':
    'COMPOUNDCRS["WGS_84/UTM ZONE 46 + EGM2008",PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'WGS_84/UTM ZONE 46+AHD71':
    'COMPOUNDCRS["WGS_84/UTM ZONE 46 + AHD71",PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'WGS_84/UTM ZONE 46+CGVD2013':
    'COMPOUNDCRS["WGS_84/UTM ZONE 46 + CGVD2013",PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'GDA2020 MGA Zone 50+Ellipsoidal':
    'PROJCRS["GDA2020 / MGA zone 50",BASEGEODCRS["GDA2020",DATUM["Geocentric Datum of Australia 2020",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["Map Grid of Australia zone 50",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",117,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Australia - onshore and offshore between 114\u00b0E and 120\u00b0E."],BBOX[-38.53,114,-12.06,120.01],ID["EPSG",7850]]',
  'GDA2020 MGA Zone 50+EGM96':
    'COMPOUNDCRS["GDA2020 MGA Zone 50 + EGM96",PROJCRS["GDA2020 / MGA zone 50",BASEGEODCRS["GDA2020",DATUM["Geocentric Datum of Australia 2020",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["Map Grid of Australia zone 50",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",117,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Australia - onshore and offshore between 114\u00b0E and 120\u00b0E."],BBOX[-38.53,114,-12.06,120.01],ID["EPSG",7850]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'GDA2020 MGA Zone 50+EGM2008':
    'COMPOUNDCRS["GDA2020 MGA Zone 50 + EGM2008",PROJCRS["GDA2020 / MGA zone 50",BASEGEODCRS["GDA2020",DATUM["Geocentric Datum of Australia 2020",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["Map Grid of Australia zone 50",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",117,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Australia - onshore and offshore between 114\u00b0E and 120\u00b0E."],BBOX[-38.53,114,-12.06,120.01],ID["EPSG",7850]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'GDA2020 MGA Zone 50+AHD71':
    'COMPOUNDCRS["GDA2020 MGA Zone 50 + AHD71",PROJCRS["GDA2020 / MGA zone 50",BASEGEODCRS["GDA2020",DATUM["Geocentric Datum of Australia 2020",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["Map Grid of Australia zone 50",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",117,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Australia - onshore and offshore between 114\u00b0E and 120\u00b0E."],BBOX[-38.53,114,-12.06,120.01],ID["EPSG",7850]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'GDA2020 MGA Zone 50+CGVD2013':
    'COMPOUNDCRS["GDA2020 MGA Zone 50 + CGVD2013",PROJCRS["GDA2020 / MGA zone 50",BASEGEODCRS["GDA2020",DATUM["Geocentric Datum of Australia 2020",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["Map Grid of Australia zone 50",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",117,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Australia - onshore and offshore between 114\u00b0E and 120\u00b0E."],BBOX[-38.53,114,-12.06,120.01],ID["EPSG",7850]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'WGS_84/UTM ZONE 19N+Ellipsoidal':
    'PROJCRS["WGS 84 / UTM zone 19N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0W and 66\u00b0W, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Aruba. Bahamas. Brazil. Canada - New Brunswick (NB); Labrador; Nunavut; Nova Scotia (NS); Quebec. Colombia. Dominican Republic. Greenland. Netherlands Antilles. Puerto Rico. Turks and Caicos Islands. United States. Venezuela."],BBOX[0,-72,84,-66],ID["EPSG",32619]]',
  'WGS_84/UTM ZONE 19N+EGM96':
    'COMPOUNDCRS["WGS_84/UTM ZONE 19N + EGM96",PROJCRS["WGS 84 / UTM zone 19N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0W and 66\u00b0W, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Aruba. Bahamas. Brazil. Canada - New Brunswick (NB); Labrador; Nunavut; Nova Scotia (NS); Quebec. Colombia. Dominican Republic. Greenland. Netherlands Antilles. Puerto Rico. Turks and Caicos Islands. United States. Venezuela."],BBOX[0,-72,84,-66],ID["EPSG",32619]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'WGS_84/UTM ZONE 19N+EGM2008':
    'COMPOUNDCRS["WGS_84/UTM ZONE 19N + EGM2008",PROJCRS["WGS 84 / UTM zone 19N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0W and 66\u00b0W, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Aruba. Bahamas. Brazil. Canada - New Brunswick (NB); Labrador; Nunavut; Nova Scotia (NS); Quebec. Colombia. Dominican Republic. Greenland. Netherlands Antilles. Puerto Rico. Turks and Caicos Islands. United States. Venezuela."],BBOX[0,-72,84,-66],ID["EPSG",32619]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'WGS_84/UTM ZONE 19N+AHD71':
    'COMPOUNDCRS["WGS_84/UTM ZONE 19N + AHD71",PROJCRS["WGS 84 / UTM zone 19N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0W and 66\u00b0W, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Aruba. Bahamas. Brazil. Canada - New Brunswick (NB); Labrador; Nunavut; Nova Scotia (NS); Quebec. Colombia. Dominican Republic. Greenland. Netherlands Antilles. Puerto Rico. Turks and Caicos Islands. United States. Venezuela."],BBOX[0,-72,84,-66],ID["EPSG",32619]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'WGS_84/UTM ZONE 19N+CGVD2013':
    'COMPOUNDCRS["WGS_84/UTM ZONE 19N + CGVD2013",PROJCRS["WGS 84 / UTM zone 19N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0W and 66\u00b0W, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Aruba. Bahamas. Brazil. Canada - New Brunswick (NB); Labrador; Nunavut; Nova Scotia (NS); Quebec. Colombia. Dominican Republic. Greenland. Netherlands Antilles. Puerto Rico. Turks and Caicos Islands. United States. Venezuela."],BBOX[0,-72,84,-66],ID["EPSG",32619]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'NAD83(CSRS) / UTM Zone 19N+Ellipsoidal':
    'PROJCRS["NAD83(CSRS) / UTM zone 19N",BASEGEODCRS["NAD83(CSRS)",DATUM["NAD83 Canadian Spatial Reference System",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Canada between 72\u00b0W and 66\u00b0W onshore and offshore - New Brunswick, Labrador, Nova Scotia, Nunavut, Quebec."],BBOX[40.8,-72,84,-66],ID["EPSG",2960]]',
  'NAD83(CSRS) / UTM Zone 19N+EGM96':
    'COMPOUNDCRS["NAD83(CSRS) / UTM Zone 19N + EGM96",PROJCRS["NAD83(CSRS) / UTM zone 19N",BASEGEODCRS["NAD83(CSRS)",DATUM["NAD83 Canadian Spatial Reference System",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Canada between 72\u00b0W and 66\u00b0W onshore and offshore - New Brunswick, Labrador, Nova Scotia, Nunavut, Quebec."],BBOX[40.8,-72,84,-66],ID["EPSG",2960]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'NAD83(CSRS) / UTM Zone 19N+EGM2008':
    'COMPOUNDCRS["NAD83(CSRS) / UTM Zone 19N + EGM2008",PROJCRS["NAD83(CSRS) / UTM zone 19N",BASEGEODCRS["NAD83(CSRS)",DATUM["NAD83 Canadian Spatial Reference System",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Canada between 72\u00b0W and 66\u00b0W onshore and offshore - New Brunswick, Labrador, Nova Scotia, Nunavut, Quebec."],BBOX[40.8,-72,84,-66],ID["EPSG",2960]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'NAD83(CSRS) / UTM Zone 19N+AHD71':
    'COMPOUNDCRS["NAD83(CSRS) / UTM Zone 19N + AHD71",PROJCRS["NAD83(CSRS) / UTM zone 19N",BASEGEODCRS["NAD83(CSRS)",DATUM["NAD83 Canadian Spatial Reference System",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Canada between 72\u00b0W and 66\u00b0W onshore and offshore - New Brunswick, Labrador, Nova Scotia, Nunavut, Quebec."],BBOX[40.8,-72,84,-66],ID["EPSG",2960]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'NAD83(CSRS) / UTM Zone 19N+CGVD2013':
    'COMPOUNDCRS["NAD83(CSRS) / UTM Zone 19N + CGVD2013",PROJCRS["NAD83(CSRS) / UTM zone 19N",BASEGEODCRS["NAD83(CSRS)",DATUM["NAD83 Canadian Spatial Reference System",ELLIPSOID["GRS 1980",6378137,298.257222101,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 19N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",-69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Canada between 72\u00b0W and 66\u00b0W onshore and offshore - New Brunswick, Labrador, Nova Scotia, Nunavut, Quebec."],BBOX[40.8,-72,84,-66],ID["EPSG",2960]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]]',
  'Arc 1950 / UTM Zone 35S+Ellipsoidal':
    'PROJCRS["Arc 1950 / UTM zone 35S",BASEGEODCRS["Arc 1950",DATUM["Arc 1950",ELLIPSOID["Clarke 1880 (Arc)",6378249.145,293.4663077,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 35S",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",27,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Botswana - east of 24\u00b0E; Zambia - between 24\u00b0E and 30\u00b0E; Zimbabwe - west of 30\u00b0E ."],BBOX[-25.84,24,-8.31,30],ID["EPSG",20935]]',
  'Arc 1950 / UTM Zone 35S+EGM96':
    'COMPOUNDCRS["Arc 1950 / UTM Zone 35S + EGM96",PROJCRS["Arc 1950 / UTM zone 35S",BASEGEODCRS["Arc 1950",DATUM["Arc 1950",ELLIPSOID["Clarke 1880 (Arc)",6378249.145,293.4663077,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 35S",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",27,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Botswana - east of 24\u00b0E; Zambia - between 24\u00b0E and 30\u00b0E; Zimbabwe - west of 30\u00b0E ."],BBOX[-25.84,24,-8.31,30],ID["EPSG",20935]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]',
  'Arc 1950 / UTM Zone 35S+EGM2008':
    'COMPOUNDCRS["Arc 1950 / UTM Zone 35S + EGM2008",PROJCRS["Arc 1950 / UTM zone 35S",BASEGEODCRS["Arc 1950",DATUM["Arc 1950",ELLIPSOID["Clarke 1880 (Arc)",6378249.145,293.4663077,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 35S",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",27,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Botswana - east of 24\u00b0E; Zambia - between 24\u00b0E and 30\u00b0E; Zimbabwe - west of 30\u00b0E ."],BBOX[-25.84,24,-8.31,30],ID["EPSG",20935]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]',
  'Arc 1950 / UTM Zone 35S+AHD71':
    'COMPOUNDCRS["Arc 1950 / UTM Zone 35S + AHD71",PROJCRS["Arc 1950 / UTM zone 35S",BASEGEODCRS["Arc 1950",DATUM["Arc 1950",ELLIPSOID["Clarke 1880 (Arc)",6378249.145,293.4663077,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 35S",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",27,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Botswana - east of 24\u00b0E; Zambia - between 24\u00b0E and 30\u00b0E; Zimbabwe - west of 30\u00b0E ."],BBOX[-25.84,24,-8.31,30],ID["EPSG",20935]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]',
  'Arc 1950 / UTM Zone 35S+CGVD2013':
    'COMPOUNDCRS["Arc 1950 / UTM Zone 35S + CGVD2013",PROJCRS["Arc 1950 / UTM zone 35S",BASEGEODCRS["Arc 1950",DATUM["Arc 1950",ELLIPSOID["Clarke 1880 (Arc)",6378249.145,293.4663077,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 35S",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",27,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",10000000,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Engineering survey, topographic mapping."],AREA["Botswana - east of 24\u00b0E; Zambia - between 24\u00b0E and 30\u00b0E; Zimbabwe - west of 30\u00b0E ."],BBOX[-25.84,24,-8.31,30],ID["EPSG",20935]],VERTCRS["CGVD2013(CGG2013) height",VDATUM["Canadian Geodetic Vertical Datum of 2013 (CGG2013)"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy, engineering survey, topographic mapping."],AREA["Canada - onshore and offshore - Alberta; British Columbia; Manitoba; New Brunswick; Newfoundland and Labrador; Northwest Territories; Nova Scotia; Nunavut; Ontario; Prince Edward Island; Quebec; Saskatchewan; Yukon."],BBOX[38.21,-141.01,86.46,-40.73],ID["EPSG",6647]]',
};

export const WKTCrsToNameMapping = {
  'GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]]':
    [HorizontalCRS.WGS84, VerticalCRS.ELLIPSOIDAL],

  'COMPOUNDCRS["WGS_84 + EGM96",GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]':
    [HorizontalCRS.WGS84, VerticalCRS.EGM96],

  'COMPOUNDCRS["WGS_84 + EGM2008",GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]':
    [HorizontalCRS.WGS84, VerticalCRS.EGM2008],

  'COMPOUNDCRS["WGS_84 + AHD71",GEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",4326]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]':
    [HorizontalCRS.WGS84, VerticalCRS.AHD71],

  'PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]]':
    [HorizontalCRS.WGS84_UTM_ZONE_42, VerticalCRS.ELLIPSOIDAL],

  'COMPOUNDCRS["WGS_84/UTM ZONE 42 + EGM96",PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_42, VerticalCRS.EGM96],

  'COMPOUNDCRS["WGS_84/UTM ZONE 42 + EGM2008",PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_42, VerticalCRS.EGM2008],

  'COMPOUNDCRS["WGS_84/UTM ZONE 42 + AHD71",PROJCRS["WGS 84 / UTM zone 42N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 42N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",69,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 66\u00b0E and 72\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Afghanistan. India. Kazakhstan. Kyrgyzstan. Pakistan. Russian Federation. Tajikistan. Uzbekistan."],BBOX[0,66,84,72],ID["EPSG",32642]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_42, VerticalCRS.AHD71],

  'PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]]':
    [HorizontalCRS.WGS84_UTM_ZONE_43, VerticalCRS.ELLIPSOIDAL],

  'COMPOUNDCRS["WGS_84/UTM ZONE 43 + EGM96",PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_43, VerticalCRS.EGM96],

  'COMPOUNDCRS["WGS_84/UTM ZONE 43 + EGM2008",PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_43, VerticalCRS.EGM2008],

  'COMPOUNDCRS["WGS_84/UTM ZONE 43 + AHD71",PROJCRS["WGS 84 / UTM zone 43N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 43N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",75,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 72\u00b0E and 78\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Maldives. Pakistan. Russian Federation. Tajikistan."],BBOX[0,72,84,78],ID["EPSG",32643]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_43, VerticalCRS.AHD71],

  'PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]]':
    [HorizontalCRS.WGS84_UTM_ZONE_44, VerticalCRS.ELLIPSOIDAL],

  'COMPOUNDCRS["WGS_84/UTM ZONE 44 + EGM96",PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_44, VerticalCRS.EGM96],

  'COMPOUNDCRS["WGS_84/UTM ZONE 44 + EGM2008",PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_44, VerticalCRS.EGM2008],

  'COMPOUNDCRS["WGS_84/UTM ZONE 44 + AHD71",PROJCRS["WGS 84 / UTM zone 44N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 44N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",81,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 78\u00b0E and 84\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. China. India. Kazakhstan. Kyrgyzstan. Nepal. Russian Federation. Sri Lanka."],BBOX[0,78,84,84],ID["EPSG",32644]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_44, VerticalCRS.AHD71],

  'PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]]':
    [HorizontalCRS.WGS84_UTM_ZONE_45, VerticalCRS.ELLIPSOIDAL],

  'COMPOUNDCRS["WGS_84/UTM ZONE 45 + EGM96",PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_45, VerticalCRS.EGM96],

  'COMPOUNDCRS["WGS_84/UTM ZONE 45 + EGM2008",PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_45, VerticalCRS.EGM2008],

  'COMPOUNDCRS["WGS_84/UTM ZONE 45 + AHD71",PROJCRS["WGS 84 / UTM zone 45N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 45N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",87,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 84\u00b0E and 90\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. India. Kazakhstan. Mongolia. Nepal. Russian Federation."],BBOX[0,84,84,90],ID["EPSG",32645]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_45, VerticalCRS.AHD71],

  'PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]]':
    [HorizontalCRS.WGS84_UTM_ZONE_46, VerticalCRS.ELLIPSOIDAL],

  'COMPOUNDCRS["WGS_84/UTM ZONE 46 + EGM96",PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]],VERTCRS["EGM96 height",VDATUM["EGM96 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",5773]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_46, VerticalCRS.EGM96],

  'COMPOUNDCRS["WGS_84/UTM ZONE 46 + EGM2008",PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]],VERTCRS["EGM2008 height",VDATUM["EGM2008 geoid"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Geodesy."],AREA["World."],BBOX[-90,-180,90,180],ID["EPSG",3855]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_46, VerticalCRS.EGM2008],

  'COMPOUNDCRS["WGS_84/UTM ZONE 46 + AHD71",PROJCRS["WGS 84 / UTM zone 46N",BASEGEODCRS["WGS 84",DATUM["World Geodetic System 1984",ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]]],CONVERSION["UTM zone 46N",METHOD["Transverse Mercator",ID["EPSG",9807]],PARAMETER["Latitude of natural origin",0,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8801]],PARAMETER["Longitude of natural origin",93,ANGLEUNIT["degree",0.0174532925199433],ID["EPSG",8802]],PARAMETER["Scale factor at natural origin",0.9996,SCALEUNIT["unity",1],ID["EPSG",8805]],PARAMETER["False easting",500000,LENGTHUNIT["metre",1],ID["EPSG",8806]],PARAMETER["False northing",0,LENGTHUNIT["metre",1],ID["EPSG",8807]]],CS[Cartesian,2],AXIS["(E)",east,ORDER[1],LENGTHUNIT["metre",1]],AXIS["(N)",north,ORDER[2],LENGTHUNIT["metre",1]],SCOPE["Navigation and medium accuracy spatial referencing."],AREA["Between 90\u00b0E and 96\u00b0E, northern hemisphere between equator and 84\u00b0N, onshore and offshore. Bangladesh. Bhutan. China. Indonesia. Mongolia. Myanmar (Burma). Russian Federation."],BBOX[0,90,84,96],ID["EPSG",32646]],VERTCRS["AHD height",VDATUM["Australian Height Datum"],CS[vertical,1],AXIS["gravity-related height (H)",up,LENGTHUNIT["metre",1]],SCOPE["Cadastre, engineering surveying applications over distances up to 10km."],AREA["Australia - Australian Capital Territory, New South Wales, Northern Territory, Queensland, South Australia, Tasmania, Western Australia and Victoria - onshore. Christmas Island - onshore. Cocos and Keeling Islands - onshore."],BBOX[-43.7,96.76,-9.86,153.69],ID["EPSG",5711]]]':
    [HorizontalCRS.WGS84_UTM_ZONE_46, VerticalCRS.AHD71],
};

export const horizontalCRSMapping = {
  [HorizontalCRS.WGS84]: 'EPSG:4326',
  [HorizontalCRS.WGS84_UTM_ZONE_42]: 'EPSG:32642',
  [HorizontalCRS.WGS84_UTM_ZONE_43]: 'EPSG:32643',
  [HorizontalCRS.WGS84_UTM_ZONE_44]: 'EPSG:32644',
  [HorizontalCRS.WGS84_UTM_ZONE_45]: 'EPSG:32645',
  [HorizontalCRS.WGS84_UTM_ZONE_46]: 'EPSG:32646',
  [HorizontalCRS.GDA2020_MGA_ZONE_50]: 'EPSG:7850',
};

export const horizontalCrsSridMapping = {
  4326: HorizontalCRS.WGS84,
  32642: HorizontalCRS.WGS84_UTM_ZONE_42,
  32643: HorizontalCRS.WGS84_UTM_ZONE_43,
  32644: HorizontalCRS.WGS84_UTM_ZONE_44,
  32645: HorizontalCRS.WGS84_UTM_ZONE_45,
  32646: HorizontalCRS.WGS84_UTM_ZONE_46,
  7850: HorizontalCRS.GDA2020_MGA_ZONE_50,
};
