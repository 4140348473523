import { Input, SliderComponent } from '@aus-platform/design-system';
import { isArray, isEmpty } from 'lodash';
import { preventNonNumber } from 'shared/helpers';

type OpacitySliderProps = {
  opacity: number;
  onChangeOpacity: (opacity: number) => void;
};

export const OpacitySlider: React.FC<OpacitySliderProps> = ({
  opacity = 100,
  onChangeOpacity,
}) => {
  // Handlers.
  const onChangeSlider = (value: number[] | number) => {
    if (isArray(value)) {
      onChangeOpacity(value[0]);
    } else {
      onChangeOpacity(value);
    }
  };

  const onChangeInput = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (isEmpty(value)) {
      /**
       * Typecasting because, when user is typing through keyboard
       * they should be able to clear the input completely i.e it is supposed to accept empty string "".
       *  Ref: https://aarav-unmanned-systems.atlassian.net/browse/RAIN-4415?atlOrigin=eyJpIjoiM2FkNWRlZDJmZGMwNDNlYmE4NThjYmVjODkyNWQ1MjgiLCJwIjoiaiJ9
       */
      onChangeOpacity(value as any);
    } else {
      const opacityValue = Number(value);
      onChangeOpacity(Math.max(0, Math.min(100, opacityValue)));
    }
  };

  return (
    <div className="opacity-slider">
      <SliderComponent
        value={opacity}
        onChange={onChangeSlider}
        tipProps={{ visible: false }}
      />
      <div className="opacity-slider__input-container">
        <Input.Number
          value={opacity}
          min={0}
          max={100}
          onKeyDown={preventNonNumber}
          onChange={onChangeInput}
        />
      </div>
    </div>
  );
};
