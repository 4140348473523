import LoginBackground from './login/background.svg';
import AereoCloudLogoBlue from './logos/aereo-cloud-logo-blue.svg';
import AereoCloudLogoWhite from './logos/aereo-cloud-logo-white.svg';
import AereoWhiteLogo from './logos/aereo-logo-white.svg';
import AereoLogo from './logos/aereo-logo.svg';
import WhiteLogo from './logos/aus-logo-white.svg';
import DefaultOrgLogo from './logos/default-org-logo.svg';
import LogoFill from './logos/logo-fill.svg';
import Logo from './logos/logo.svg';
import Legend from './map-3d/legend.png';
import DogServer from './network-error/dog-server.svg';
import ThinkingMan from './network-error/network-man.svg';
import Drone from './not-found/drone.svg';
import ManWithTelescope from './not-found/man.svg';
import Tree from './not-found/tree.svg';
import Connect from './processing/connect.svg';
import ProjectImg1 from './projects/1.svg';
import {
  default as ProjectImg2,
  default as ProjectImg3,
  default as ProjectImg4,
} from './projects/2.svg';

export const Image = {
  AereoCloudLogoBlue,
  AereoCloudLogoWhite,
  AereoLogo,
  AereoWhiteLogo,
  Connect,
  DefaultOrgLogo,
  DogServer,
  Drone,
  Legend,
  LoginBackground,
  Logo,
  LogoFill,
  ManWithTelescope,
  ProjectImg1,
  ProjectImg2,
  ProjectImg3,
  ProjectImg4,
  ThinkingMan,
  Tree,
  WhiteLogo,
};
