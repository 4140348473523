export enum SearchPlaceholder {
  Iteration = 'iteration',
  Site = 'site',
  Layer = 'layer',
  Empty = '',
}

export enum DatasetList {
  Site = 'site',
  Iteration = 'iteration',
  Layer = 'layer',
  None = 'none',
}
