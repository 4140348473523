import { Button, ButtonVariant, Spinner } from '@aus-platform/design-system';
import { useEffect, useState } from 'react';
import { filter, includes, isEmpty } from 'lodash';
import { haulRoadAnalyticsTabs } from '../../enums';
import { removeWorkspaceLayersFromMap } from '../../../map-3d-workspace';
import { HaulRoad } from './haul-road';
import {
  handleResponseErrorMessage,
  HaulRoadListItem,
  useDeleteHaulRoads,
  useHaulRoads,
} from 'shared/api';
import {
  removeWorkspaceLayers,
  SelectedTerrainIteration,
  selectMap3DState,
  selectMap3dWorkspace,
} from 'map-3d/shared/map-3d-slices';
import { ConfirmationModal } from 'src/shared/components';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';

type HaulRoadListProps = {
  onClickHaulRoad: (haulRoad: HaulRoadListItem) => void;
  iteration: SelectedTerrainIteration;
  activeTabKey: string;
  setActiveTabKey;
};

export const HaulRoadList: React.FC<HaulRoadListProps> = ({
  onClickHaulRoad,
  iteration,
  activeTabKey,
  setActiveTabKey,
}) => {
  // States.
  const [isShowDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);

  const [currentHaulRoadId, setCurrentHaulRoadId] = useState<string>('');

  // Redux.
  const { workspaceLayers, drawingTools } =
    useAppSelector(selectMap3dWorkspace);
  const { cesiumProxy } = useAppSelector(selectMap3DState);
  const dispatch = useAppDispatch();

  // APIs.
  const {
    data: haulRoadsResponseData,
    error: haulRoadsErrorResponse,
    isError: isErrorHaulRoads,
    isLoading: isLoadingHaulRoads,
    refetch: refetchHaulRoads,
  } = useHaulRoads({
    iteration: iteration.id,
  });

  const {
    mutate: sendDeleteHaulRoadRequest,
    data: deleteHaulRoadResponse,
    error: deleteHaulRoadErrorResponse,
    isError: isErrorDeleteHaulRoad,
    isSuccess: isSuccessDeleteHaulRoad,
  } = useDeleteHaulRoads();

  // useEffects.
  useEffect(() => {
    handleResponseErrorMessage(isErrorHaulRoads, haulRoadsErrorResponse);
  }, [isErrorHaulRoads, haulRoadsErrorResponse]);

  useEffect(() => {
    if (activeTabKey === haulRoadAnalyticsTabs.Outputs) {
      refetchHaulRoads();
    }
  }, [activeTabKey]);

  useEffect(() => {
    handleResponseErrorMessage(
      isErrorDeleteHaulRoad,
      deleteHaulRoadErrorResponse,
    );
  }, [isErrorDeleteHaulRoad, deleteHaulRoadErrorResponse]);

  useEffect(() => {
    if (isSuccessDeleteHaulRoad && deleteHaulRoadResponse) {
      // Remove layers from the map.
      removeWorkspaceLayersFromMap(
        filter(workspaceLayers, (workspaceLayer) =>
          includes(deleteHaulRoadResponse.layerIds, workspaceLayer.id),
        ),
        drawingTools,
        cesiumProxy,
      );

      // Removed layers from the workspace.
      dispatch(removeWorkspaceLayers(deleteHaulRoadResponse.layerIds));

      hideDeleteConfirmationModal();
      refetchHaulRoads();
    }
  }, [isSuccessDeleteHaulRoad]);

  // Handlers.
  const hideDeleteConfirmationModal = () =>
    setShowDeleteConfirmationModal(false);

  const showDeleteConfirmationModal = () =>
    setShowDeleteConfirmationModal(true);

  const onClickDeleteHaulRoad = (id) => {
    showDeleteConfirmationModal();
    setCurrentHaulRoadId(id);
  };

  const onClickDeleteHaulRoadSubmit = () => {
    sendDeleteHaulRoadRequest(currentHaulRoadId);
  };

  const onClickNewHaulRoad = () => {
    setActiveTabKey(haulRoadAnalyticsTabs.Generate);
  };

  return (
    <div className="haul-road-list">
      {isLoadingHaulRoads ? (
        <Spinner />
      ) : isEmpty(haulRoadsResponseData) ? (
        <div className="haul-road-list__empty">
          <div className="haul-road-list__empty__text">
            No entities are present
            <Button
              variant={ButtonVariant.Primary}
              onClick={onClickNewHaulRoad}
            >
              Generate New Haul Road
            </Button>
          </div>
        </div>
      ) : (
        haulRoadsResponseData?.map((haulRoad) => (
          <HaulRoad
            key={'haul-road' + haulRoad.id}
            haulRoad={haulRoad}
            onClick={onClickHaulRoad}
            onClickDeleteHaulRoad={onClickDeleteHaulRoad}
          />
        ))
      )}
      {isShowDeleteConfirmationModal && (
        <ConfirmationModal
          title="Delete Confirmation"
          message={
            <div>
              Are you sure you want to delete? This action will permanently
              remove the selected Haul Road layers
            </div>
          }
          onSubmit={onClickDeleteHaulRoadSubmit}
          onClose={hideDeleteConfirmationModal}
          confirmText="Delete"
          isConfirmDanger
          className="hra-output-delete-confirmation-modal"
        />
      )}
    </div>
  );
};
