export enum SiteSubmitButtonText {
  DONE = 'Done',
  CANCEL = 'Cancel',
  NEXT = 'Next',
  UPLOADING = 'Uploading',
  PROCESSING = 'Processing',
  ADD_SITE = 'Add Site',
  UPDATE_SITE = 'Update Site',
}

export enum AccessType {
  Basic = 'basic',
  Advance = 'advance',
}
