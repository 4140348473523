import { SlopeInfo } from './types';

export const slopeInfoInitialState: SlopeInfo = {
  pointA: {
    point: null,
    lineIndex: null,
  },
  pointB: {
    point: null,
    lineIndex: null,
  },
  slopeDetails: null,
};
