export enum GeometryType {
  MultiPolygon = 'MultiPolygon',
  Polygon = 'Polygon',
  Line = 'LineString',
  MultiLine = 'MultiLineString',
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  Textbox = 'Label',
}

export enum WKTGeometryType {
  MULTIPOLYGON = 'MULTIPOLYGON',
  POLYGON = 'POLYGON',
  LINE = 'LINESTRING',
  MULTILINE = 'MULTILINESTRING',
  POINT = 'POINT',
  MULTIPOINT = 'MULTIPOINT',
}
