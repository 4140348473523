import { ColorClass, Icon, IconIdentifier } from '@aus-platform/design-system';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderTitleContext } from 'shared/context';
import { RoutesEnum } from 'shared/routes';

type HeaderTitleProps = {
  text: string;
};

const routesWithNoBackButton = [
  RoutesEnum.Dashboard.toString(),
  RoutesEnum.Users.toString(),
  RoutesEnum.UserGroups.toString(),
  RoutesEnum.Projects.toString(),
  RoutesEnum.Reports.toString(),
  RoutesEnum.AccessTags.toString(),
];

export const HeaderTitle: React.FC<HeaderTitleProps> = ({ text }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { headerBackButtonRoute } = useContext(HeaderTitleContext);
  const showBackButton = !routesWithNoBackButton.includes(location.pathname);

  const onBackButtonClick = () => {
    navigate(headerBackButtonRoute);
  };

  return (
    <div className="header-title header-txt-1 bold-txt">
      {showBackButton && (
        <Icon
          identifier={IconIdentifier.ArrowLeft}
          colorClass={ColorClass.Neutral300}
          onClick={onBackButtonClick}
          className="me-3 cursor-pointer"
        />
      )}
      <span>{text}</span>
    </div>
  );
};
