export enum HorizontalCRS {
  WGS84 = 'WGS_84',
  WGS84_UTM_ZONE_42 = 'WGS_84/UTM ZONE 42',
  WGS84_UTM_ZONE_43 = 'WGS_84/UTM ZONE 43',
  WGS84_UTM_ZONE_44 = 'WGS_84/UTM ZONE 44',
  WGS84_UTM_ZONE_45 = 'WGS_84/UTM ZONE 45',
  WGS84_UTM_ZONE_46 = 'WGS_84/UTM ZONE 46',
  GDA2020_MGA_ZONE_50 = 'GDA2020 MGA Zone 50',
}

export enum VerticalCRS {
  EGM96 = 'EGM96',
  ELLIPSOIDAL = 'Ellipsoidal',
  EGM2008 = 'EGM2008',
  AHD71 = 'AHD71',
}

export enum EPSGCode {
  WGS84 = 4326,
  WGS84_UTM_ZONE_42 = 32642,
  WGS84_UTM_ZONE_43 = 32643,
  WGS84_UTM_ZONE_44 = 32644,
  WGS84_UTM_ZONE_45 = 32645,
  WGS84_UTM_ZONE_46 = 32646,
  GDA2020_MGA_ZONE_50 = 7850,
}
