import React, { useState } from 'react';
import {
  GeneratingOutput,
  Map3DNoTerrainSelected,
  Map3DSidebarOption,
} from '../../shared';
import { GenerateSmartDetectForm } from './generate-smart-detect-form';
import { useAppSelector } from 'src/app/hooks';
import { selectMap3dDataset } from 'src/map-3d/shared/map-3d-slices';

export const Map3DSmartDetect: React.FC = () => {
  // States.
  const [showSmartDetectForm, setShowSmartDetectForm] = useState(true);

  // Selectors.
  const { selectedTerrainIteration } = useAppSelector(selectMap3dDataset);

  // Render.
  if (!selectedTerrainIteration?.id) {
    return <Map3DNoTerrainSelected />;
  }

  if (!showSmartDetectForm) {
    return (
      <GeneratingOutput
        setShowCurrentForm={setShowSmartDetectForm}
        currentAnalyticsOption={Map3DSidebarOption.SmartDetect}
        text="Generating Outputs"
      />
    );
  }

  return (
    <GenerateSmartDetectForm setShowSmartDetectForm={setShowSmartDetectForm} />
  );
};
