export const HraGuide = () => {
  return (
    <div className="guide-page map-3d-sidebar-content">
      <ul>
        <li>
          <span> Step 1 - </span> <p> Go to Generate tab </p>
        </li>
        <li>
          <span> Step 2 - </span> <p>Enter haul road name</p>
        </li>
        <li>
          <span> Step 3 - </span>{' '}
          <p>
            Draw or select a layer from workspace to define origin & destination
            points
          </p>
        </li>
        <li>
          <span> Step 4 - </span> <p>Fill in the attributes</p>
        </li>
        <li>
          <span> Step 5 - </span>
          <p>Click &quot;Generate Haul Road&quot; button</p>
        </li>
        <li>
          <span> Step 6 - </span>
          <p>
            Wait for system to save and process your request. Your processed
            request will be available always when you login in the
            &quot;Outputs&quot; tab
          </p>
        </li>
      </ul>
    </div>
  );
};
